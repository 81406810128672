import { extendTheme } from '@chakra-ui/react'

// Define the config
const config = {
  styles: {
    global: {
      'html, body, #__next': {
        height: '100%',
      },
      '.react-datepicker-popper': {
        zIndex: 10,
      },
    },
  },
}

// extend the theme
export const theme = extendTheme(config)
