import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SessionProvider } from 'next-auth/react'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '../theme'
import { authAxios } from '@/lib/axios/config'
import * as Sentry from '@sentry/nextjs'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App({ Component, pageProps }) {
  const { session } = pageProps

  if (session?.user) {
    Sentry.setUser({
      id: session.user.id,
    })
  } else {
    Sentry.configureScope((scope) => scope.setUser(null))
  }

  authAxios.interceptors.request.use(async (config) => {
    if (session?.user?.authorization) {
      config.headers.Authorization = session?.user?.authorization
    }
    return config
  })

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <Component {...pageProps} />
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SessionProvider>
  )
}

export default App
